<template>
	<div class="contant">
		<banner></banner>
		<div class="mainCon">
			<div class="jnts_con_title">
				学习记录
			</div>
			<!-- 课程列表 -->
			<div class="videoCon">
				<videoItem :item="item" v-for="(item, index) in list" :key="item.course_id"
					@click="toLive(item.type, item.course_id)"></videoItem>
				<pages :page="page" :page-size="pageSize" :total="total" @change="pageChange"></pages>
			</div>
		</div>
		<foot></foot>
	</div>
</template>

<script>
	import banner from "@/components/pc/banner"
	import foot from "@/components/pc/foot"
	import videoItem from "@/components/pc/videoItem"
	import Pages from '@/components/pages'
	export default {
		components: {
			banner,
			foot,
			videoItem,
			Pages
		},
		data() {
			return {
				// 数据列表
				list: [],
				// 分页
				page: 1,
				pageSize: 9,
				total: 0
			}
		},
		mounted() {
			// 数据列表
			this.init()
		},
		methods: {
			// 查看详情
			toLive(type, course_id) {
				console.log(type, course_id)
				if (type == 0) {
					this.$toast.fail("课程未开始")
					return;
				}
				if (type == -1) {
					this.$toast.fail("课程已结束")
					return;
				}
				this.$router.push('/pc/video?id=' + course_id);
			},
			// 数据列表
			async init() {
				const res = await this.$api.jntsFindHistory({
					page: this.page,
					pageSize: this.pageSize
				});
				this.list = res.data.list;
				this.total = res.data.totalRow;
			},
			// 分页
			pageChange(page) {
				this.page = page;
				this.init();
			}
		}
	}
</script>

<style scoped lang="less">
	.contant {
		width: 100%;
		min-width: 1200px;
		background: url('../../assets/img/pc/bg.jpg') no-repeat;
		background-size: cover;

		.mainCon {
			width: 1200px;
			margin: 0 auto;
			min-height: 500px;

			.jnts_con_title {
				margin: 35px 0;
				color: #B5802B;
				font-size: 30px;
				font-weight: bold;
				border-left: 5px solid #B5802B;
				padding-left: 20px
			}

			// 课程列表
			.videoCon {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
			}
		}
	}
</style>